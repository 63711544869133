<template>
  <svg width="100" viewBox="0 0 175 203" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path fill="#346" d="M0 193h175V8H0z" />
      <path
        d="M18 193h1v-13h-1v13zm0-14h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1V82h-1v19zm0-20h1V62h-1v19zm0-19h1V43h-1v19zm0-20h1V23h-1v19zm0-20h1V9h-1v13zM38 193h1v-13h-1v13zm0-14h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1V82h-1v19zm0-20h1V62h-1v19zm0-19h1V43h-1v19zm0-20h1V23h-1v19zm0-20h1V9h-1v13zM57 193h1v-13h-1v13zm0-14h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1V82h-1v19zm0-20h1V62h-1v19zm0-19h1V43h-1v19zm0-20h1V23h-1v19zm0-20h1V9h-1v13zM78 193h1v-13h-1v13zm0-14h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1V82h-1v19zm0-20h1V62h-1v19zm0-19h1V43h-1v19zm0-20h1V23h-1v19zm0-20h1V9h-1v13zM97 193h2v-13h-2v13zm0-14h2v-19h-2v19zm0-20h2v-19h-2v19zm0-19h2v-19h-2v19zm0-20h2v-19h-2v19zm0-19h2V82h-2v19zm0-20h2V62h-2v19zm0-19h2V43h-2v19zm0-20h2V23h-2v19zm0-20h2V9h-2v13zM117 193h1v-13h-1v13zm0-14h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1V82h-1v19zm0-20h1V62h-1v19zm0-19h1V43h-1v19zm0-20h1V23h-1v19zm0-20h1V9h-1v13zM136 193h2v-13h-2v13zm0-14h2v-19h-2v19zm0-20h2v-19h-2v19zm0-19h2v-19h-2v19zm0-20h2v-19h-2v19zm0-19h2V82h-2v19zm0-20h2V62h-2v19zm0-19h2V43h-2v19zm0-20h2V23h-2v19zm0-20h2V9h-2v13zM156 193h1v-13h-1v13zm0-14h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1v-19h-1v19zm0-20h1v-19h-1v19zm0-19h1V82h-1v19zm0-20h1V62h-1v19zm0-19h1V43h-1v19zm0-20h1V23h-1v19zm0-20h1V9h-1v13z"
        fill="#696FAA"
      />
      <path
        fill="#696FAA"
        d="M0 24h175v-1H0zM0 44h175v-2H0zM0 63h175v-1H0zM0 83h175v-2H0zM0 102h175v-1H0zM0 122h175v-2H0zM0 141h175v-1H0zM0 161h175v-2H0zM0 180h175v-1H0z"
      />
      <path fill="#FFF" d="M0 9h175V0H0zM0 203h175v-10H0z" />
      <g fill="#346" fill-rule="nonzero" stroke="#A198F4" stroke-width="4">
        <path
          d="M95.18 108.05l24.306-35.075-8.41-5.926L143 52l-3.035 35.407-8.408-5.925-32.064 46.271L65.17 107.64 39.66 141 28 131.931l33.397-43.678z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ChartGraph",
};
</script>

<style scoped></style>
