<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <ConfigurationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <h4>
              Exports
              <router-link
                :to="{ name: 'export.create' }"
                class="btn btn-sm float-right btn-outline-secondary m-1"
              >
                Create an export
              </router-link>
            </h4>
          </div>
          <div v-if="Object.keys(exports.data).length !== 0" class="card-body">
            <data-table
              :headers="headers()"
              sort-url="/exports"
              :method="'get'"
              :data="exports.data"
            >
              <template #order_number="{ item }">
                {{ item.name }}
              </template>
              <template #mailable="{ item }">
                <span v-if="item.mailable" class="badge badge-success"
                  >Yes</span
                >
                <span v-else class="badge badge-secondary">No</span>
              </template>
              <template #date="{ item }">
                {{ item.created_at | formatDateHours }}
              </template>
              <template #actions="{ item }">
                <router-link
                  :to="{ name: 'export.edit', params: { id: item.id } }"
                  class="btn btn-sm btn-outline-secondary m-1"
                >
                  Edit export
                </router-link>
                <button
                  class="btn btn-sm btn-secondary m-1"
                  @click="download(item.id)"
                >
                  Download
                </button>
              </template>
            </data-table>
            <pagination
              :data="exports"
              :limit="3"
              :show-disabled="true"
              align="right"
              @pagination-change-page="getExports"
            >
              <span slot="prev-nav" class="text-capitalize"
                ><i class="fas fa-chevron-left mr-2" /> Prev</span
              >
              <span slot="next-nav" class="text-capitalize"
                >Next<i class="fas fa-chevron-right ml-2"
              /></span>
            </pagination>
          </div>
          <div v-else class="card-body">
            <p class="text-muted">
              This page shows a list of the most exports, which is synced when
              the flow API is used.
            </p>
            <div class="p-4 rounded border-zip text-center">
              <p class="mb-0">
                No recent exports has been found!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import ChartGraph from "@/components/Svg/ChartGraph.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";

export default {
  components: {
    ChartGraph,
    DataTable,
    Default,
    ConfigurationNav,
  },
  beforeRouteEnter(to, from, next) {
    let uri = "/exports?page=1";

    if (to.query.sort && to.query.dir) {
      uri += "&sort=" + to.query.sort + "&dir=" + to.query.dir;
    }

    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.exports = response.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      exports: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
    };
  },
  methods: {
    headers() {
      return [
        { title: "Name", key: "name", sortable: true, sortKey: "name" },
        {
          title: "Used with automation",
          key: "mailable",
          sortable: true,
          sortKey: "mailable",
        },
        {
          title: "Created on",
          key: "date",
          sortable: true,
          sortKey: "created_at",
        },
        { title: "Actions", key: "actions" },
      ];
    },
    getExports: function(page = 1) {
      let uri = "/exports?page=" + page;

      if (this.$route.query.sort && this.$route.query.dir) {
        uri +=
          "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
      }

      jsonAPI
        .get(uri)
        .then((response) => {
          this.exports = response.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
    download(uid) {
      jsonAPI({
        url: "/exports/download/" + uid,
        method: "GET",
        responseType: "blob",
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([resp.data], { type: "application/octet-stream" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => {
           window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
  },
};
</script>
